.assigned-people {
  margin: 10px;
}

.assigned-people > .tabs {
  display: flex;
  column-gap: 10px;
}

.assigned-people > .tabs > .tab {
  cursor: pointer;
  font-size: 12px !important;
}

.assigned-people > .tabs > .divider {
  width: 2px;
  background-color: gray;
}

.assigned-people > .tabs > .tab.active {
  color: rgb(240, 74, 0);
}

.list-assigned {
  margin-top: 12px;
}

.list-assigned > .form-select-group {
  display: flex;
  align-items: center;
  column-gap: 10px;
}