.page-wrapper {
    width: 100%;
  }
  
.page-wrapper > .container {
    margin: 24px;
}
  
.header {}
  
.header > .page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  
.header > .page-header > .filter-group {
  display: flex;
  column-gap: 10px;
}

.report-table-date-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:5px;
}
.report-table-ref-no{
  border: 1px solid #73007D;
  background-color: #ECDEFF;
  color: #73007D;
  /* height: 30px; */
  border-radius: 10px;
  text-align: center;
  font-weight: 700;
}
.report-table-save-btn{
  background-color: #F04A00;
  width: 200px;
  color: #FFFFFF;
  height: 48px;
}
.report-table-save-btn:hover{
  background-color: #F04A00;
}

.fob-table-report > .k-table-thead > tr{
  background-color: red !important;
}

.report-table-header{
  display: flex;
  justify-content: space-between;
}
.report-table-filter{
  display: flex;
  gap:24px;
  min-width: 1000px;
}
.report-table-filter-item{
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
}

.report-table-filter-select{
  width: 220px;
  height: 40px;
  border: 1px solid #CCC;
  border-radius: 5px;
}

.fob-table-report> .k-grid .k-cell-inner > .k-link{
  justify-content: center !important;
}
.report-table-functions{
  display: flex;
  gap:5px;
}

.actiontakenDiv{
  text-decoration: underline;
  color: #73007D;
  font-weight: 600;
  cursor: pointer;
}