/* .body{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
} */

.filter-popup > .k-window {
  position: absolute !important;
  right: 0;
}

.dashboard_main_box{
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 75px);
  gap: 30px;
  flex-direction: column;
}

.dashboard_title_box{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dashboard_main_text{
  font-family: Poppins, sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}