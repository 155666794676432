.bottom-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.bottom-wrapper > .load-more {
  padding: 4px;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  cursor: pointer;
}