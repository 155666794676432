.summarycheck-email-content{
    display: flex;
    flex-direction: column;
}

.summarycheck-email-container{
    padding: 0px 30px;
    margin-bottom: 50px;
}
.summarycheck-email-list{
    padding: 10px 0px;
    display: flex;
    column-gap: 5px;
}
.summarycheck-email-list div{
    background-color: #F04A00;
    padding:3px;
    color:#fff;
    display: flex;
    column-gap: 5px;
    font-size: smaller;
    border-radius:5px;
    font-family: Poppins,sans-serif !important;
    margin-top: 6px;
}
.email-template-sub-title{
    padding: 5px 0px;
    font-weight: 600;
}
.summarycheck-email-buttongroups{
    width:100%;
    margin-top: auto;
    height: 40px;
    border-top: 1px solid #CCCCCC;
    padding: 20px 0px;
}
.summarycheck-email-buttongroups div{
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    column-gap: 10px;
}
.summarycheck-email-buttongroups > div > Button{
    padding: 10px;
}
.summarycheck-email-buttongroups >div > Button:nth-child(1){
    background-color: #fff;
    color:#F04A00;
    border-color: #F04A00 !important;
}
.summarycheck-email-buttongroups > div > Button:nth-child(2){
    background-color: #F04A00;
    color:#fff;
    
}
.summarycheck-email-template-row> .k-editor> .k-content p{
    font-family: Poppins, sans-serif !important;
}
.summarycheck-email-template-content p{
    color: red;
    font-family: Poppins, sans-serif !important;
}