.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.k-expander {
    min-width: 1200px;
}
.scheck-panel .k-expander-header{
    background-color: #E7ECEF;
}
.k-expander-title{
    color: #000000 !important;
    font-weight: 600;
}
.content {
    display: flex;
    flex-direction: column;
}
.scheck-panel .k-animation-container{
    z-index: 100 !important;
}
.custom-detail-item-with-button{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.summary-check-detail-status{
    /* padding: 15px 35px;
    background-color: #E7ECEF;
    color:  */
    color: #4FC0E8;
    background:#ecf0f5 none repeat scroll 0 0;
    display: table;
    overflow: hidden;
    padding: 10px 30px;
    position: relative;
    -webkit-transition: all 0.4s ease;
    float: right;
}
.summary-check-detail-status::before{
    border-color: #4FC0E8 transparent transparent #4FC0E8;
    border-style: solid;
    border-width: 5px;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
}
.feedback-update-content{
    gap: 10px;
}
.feedback-update-button-div{
    display: flex;
    justify-content: flex-end;
}
.feedback-update-button-div > button{
    height: 40px;
    border: none;
    background-color: #F04A00;
    color:#fff;
    border-radius: 5px;
}
.update-history-username{
    font-size: medium;
    font-weight: 700;
}
.feedback-update-history{
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.feedback-onupdateform{
    display: flex;
    flex-direction: column;
    gap: 10px;
}