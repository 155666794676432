.role-detail-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  left: 260px;
  top: 80px;
  background: #ffffff;
  width: 1227px;
  height: 100px;
}
.role-detail-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: inherit;
  height: 37px;
}
.role-detail-group-div {
  display: flex;
  flex-direction: row;
  width: 86px;
  cursor: grab;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.role-detail-label {
  color: #781d7e;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}
.role-detail-icon {
  width: 13px;
  height: 24px;
}
.role-detail-edit-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 115px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #666666;
  border-radius: 8px;
}
.role-detail-edit-btn-label {
  font-weight: 600;
  color: #666666;
  font-size: 16px;
  width: 31px;
  font-style: normal;
  line-height: 130%;
  padding: 0px 8px;
}
.role-detail-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: inherit;
  height: auto;
  padding-top: 20px;
}
.role-detail-content table {
  width: inherit;
}
.role-detail- td {
  padding: 9px;
  vertical-align: top;
}
.role-detail-content td:first-child {
  width: 200px;
  font-weight: 600;
  color: #333;
  font-size: 16px;
  border-right: 1px solid #cccccc;
}
.role-edit-permission-layout {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.role-edit-permission-layout Button {
  width: 350px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #333;
}
.role-edit-permission-layout Button:hover {
  background-color: #fff;
}
.role-detail-button {
  width: 180px;
  height: 50px;
  border-radius: 8px;
}
.role-detail-button-div {
  display: flex;
  align-items: flex-start;
  gap: 50px;
}
.role-detail-cancel {
  background-color: #ffffff !important;
  color: #333;
  border: 1px solid #333 !important;
}
.role-detail-submit {
  background-color: #eb6e1f !important;
  color: #ffffff !important;
  border: 1px solid #eb6e1f !important;
}
.role-detail-cancel:hover {
  background-color: #ffffff !important;
}
.role-detail-submit:hover {
  background-color: #eb6e1f !important;
}
