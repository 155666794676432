.select-services {
  height: 100%;
}

.select-services > .container {
  margin: 24px;
}

.select-services {
  height: 100%;
}

.select-services > .container {
  margin: 24px;
}

.select-services-container {}

.select-services-container > .select-services-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-services-container > .select-services-headers > .filter-group {
  display: flex;
  column-gap: 10px;
}

.tabs {
  display: flex;
  column-gap: 10px;
  margin: 12px 0px;
}

.tabs > .tab {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.tabs > .tab.isactive {
  color: #F04A00;
}