.fullscreen-loader {
  position: absolute;
  background-color: #00000080;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.total-fullscreen-loader {
  position: absolute;
  background-color: #00000080;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  top: 0;
}
