.search-notification{
    align-items: flex-end !important;
    font-family: Poppins,sans-serif;
}

.search-notification .k-dialog-titlebar{
    border:none;
    background-color: white;
}
.noti-dialog-title{
    font-family: Poppins,sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #333333;
}
.dialog-row:first-child{
    margin-top: 0px;
}
.dialog-row{
    margin-top: 18px;
    padding: 0px 20px;    
    display: flex;
    flex-direction: column;
}
.search-row-title{
    font-weight: 600;
    font-size: 14px;
    color: black;
}
.search-input{
    margin-top: 9px;
    height: 48px;
    width: 320px;
    box-sizing: border-box;
    border: 1px solid #CCCCCC;
    border-radius: 8px; 
    box-shadow: none;
}
.search-input:focus{
    box-shadow: none;
}
.search-button{
    padding: 0px 18px 0px 24px;
    height: 48px;
    width: 170.5px;
}
.search-notification .k-dialog-actions{
    padding-inline: 20px;
    padding-block: 20px;
}
.cancel-btn{
    background-color: #ffffff;
    border: 1px solid #EB6E1F;
    border-radius: 8px;
    color: #EB6E1F;
}
.cancel-btn:hover{
    background-color: #ffffff;
}
.search-btn{
    background-color: #EB6E1F;
    border-radius: 8px;
    color:#ffffff;
}
.search-btn:hover{
    background-color: #EB6E1F;
}
.filter-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 10px;
    font-family: Poppins,sans-serif;
}
.clear-filter{
    color:#EB6E1F;
    cursor: pointer;
    letter-spacing: 0.07em;
}
.title{
    color:#333333;
    font-weight: 600;
    font-size: 20px;
}
.filter-body{
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
}
.filter-sub-title{
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    font-style: normal;
}
.filter-category-group{
    list-style-type: none;
    float: left;
    gap:8px;
    padding: 0px;
    align-items: center;
}
.filter-category-item{
    float: left;
    margin-top: 10px;
    background-color: #EB6E1F;
    box-sizing: border-box;
    align-items: center;
    padding: 8px;
    gap: 8px;    
    width: 158px;
    height: 79px;    
    background: #FFFFFF;    
    border: 1px solid #CCCCCC;
    border-radius: 8px; 
    display: flex;
    flex-direction: row;
}
.filter-category-item:nth-child(even){
    margin-left:10px;
}
.category-flex-row{
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-top: 10px;
    /* flex-direction: column; */
}
.filter-option-input{
    border: 1px solid #CCCCCC;
}
.date-option-div{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 152px;
    height: 24px;
}
.category-date-div{
    color: #000000;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap:10px;    
    /* padding: 10px 0px; */
}
.filter-date-range{
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    width: 100%;
}
.filter-date-range button{
    color:#781D7E;
    background-color: #FFFFFF;
    border:none;
}