.page-wrapper {
  width: 100%;
}

.page-wrapper > .container {
  margin: 24px;
}

.header {}

.header > .page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header > .page-header > .filter-group {
  display: flex;
  column-gap: 10px;
}

.page-body {
  width: 100%;
  overflow-y: scroll;

  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.page-body::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}


.datepicker-wrap {
  margin-bottom: 32px;
}

.datepicker-wrap > .react-datepicker-wrapper {
  width: 100%;
}
.summarycheck-detail-header{
  display: flex;
  padding: 10px 40px;
  justify-content: space-between;
}
.summarycheck-detail-header-item{
  /* padding:10px; */
}
.detail-header-back-button{
  display: flex;
  align-items: center;
  font-size: x-large;
  column-gap: 5px;
  font-weight: 600;
  color:#781D7E;
}
.header-right-button-group{
  display: flex;
  column-gap: 10px;
}
.header-right-button-group Button{
  background-color: white;
}