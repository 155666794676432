:root {
  --white: #FFFFFFff;
  --white-smoke: #F5F5F5ff;
  --pomp-and-power: #8F5793ff;
  --battleship-gray: #929292ff;
  --white-2: #FFFFFFff;
}

.row {
  display: flex;
  flex-direction: row;
}

.table-gap {
  column-gap: 4px;
}

.gap-column-item {
  column-gap: 10px;
}

.item {
  width: 50%;

  border: 1px solid #f0f0f0;
  padding: 6px;
  border-radius: 5px;
}

.item-active {
  border: 1px solid black;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left-center {
  display: flex;
  align-items: center;
}

.flex-column-gap {
  column-gap: 6px;
}

.mt-4 {
  margin-top: 4px;
}

.w-50 {
  width: 50%;
  word-wrap: break-word;
}

.label {
  word-wrap: break-word;
}

.label-group {
  word-wrap: break-word;
  width: calc(100% - 34px);
}

input[type='radio'] {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  border: 2px solid gray;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

/* input[type="radio"]:checked:before {
  background: var(--pomp-and-power);;  
} */

input[type="radio"]:checked {
  border-color: var(--pomp-and-power);
  border-width: 5px;
}

input[type='checkbox'] {
  width: 18px;
  height: 18px;
  border-color: var(--white-smoke);
}

.status-content {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 50% 50%;
}

.status-item {
  display: flex;
  column-gap: 4px;
}

.filter-date {
  display: flex;
}

.filter-date > .filter-date__item {
  display: flex;
  align-items: center;
  width: 50%;
}

.filter-date-byrange {
  margin-top: 12px;
  display: flex;
  column-gap: 8px;
}

.filter-date-byrange > .filter-date-byrange__item {
  margin: 8px 0px;
}

.filter-date-byrange > .filter-date-byrange__item > .filter-date-byrange__input {
  border-radius: 4px;
  height: 32px;
  border: 1px solid var(--battleship-gray)
}

.filter-date-byrange > .filter-date-byrange__item > .filter-date-byrange__label {
  font-weight: bold;
  margin-bottom: 4px;
}