.row-content {
  width: 100%;
  display: flex;
  column-gap: 20px;
}

.row-content > .row-title {
  width: 25%;
  font-weight: bold;
  padding: 10px 0px;
}

.row-content > .row-title.row-divider {
  border-right: 2px solid rgb(239, 240, 243);
}

.row-content > .row-body {
  width: 75%;
  padding: 10px 0px;
}

/* .k-animation-container {
  z-index: 8 !important;
} */

/* .k-animation-container {
  z-index: 1000 !important;
} */