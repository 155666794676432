.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: calc(100vw - 300px);
  min-width: 1227px;
}
.notification_body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 0px;
  gap: 24px;
  width: 100%;
  background: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;

}
.notification_addheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  gap: 8px;
  width: calc(100vw - 300px);
  height: 48px;
  /* background-color: green; */
}
.back-title-group {
  cursor: pointer;
}
.back-title {
  position: absolute;
  width: 86px;
  height: 28px;
  left: 24px;
  top: 10px;
}
.back-text {
  width: 54px;
  height: 28px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #781d7e;
  padding: 0px 10px;
}
.back-icon {
  width: 16px;
  height: 16px;
}
.auto-saved-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.auto-saved-div span {
  color: #666666;
}
.auto-saved-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  gap: 8px;
  width: 168px;
  height: 48px;
  background: #e7ecef;
  border-radius: 8px;
}
.progress-steps-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 32px;
  gap: 24px;
  height: 100px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.progress-steps-inside-group {
  width: 1200px;
  flex: none;
  order: 0;
  flex-grow: 0;
  align-items: center;
}
.notification-steps {
  width: 50%;
  left: 25%;
  /* color: #CCCCCC; */
}
.k-step-indicator-text {
  display: none;
}

.text-center-align {
  text-align: center;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 32px;
  gap: 24px;
  min-height: 500px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  padding-bottom: 40;
  /* background-color: aqua; */
}
.distribution-title {
  width: 141px;
  height: 21px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #000000;
}
.distribution-list {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.distribution-list .k-radio {
  border: 1px solid #cccccc;
}
.distribution-list .k-radio-label {
  padding-left: 10px;
  padding-bottom: 10px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #000000;
}
.distribution-list li {
  padding: 5px;
}
.select-user-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0px;
  gap: 8px;
  width: 1200px;
}
.tab-name {
  width: 188px;
  height: 21px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #000000;
}
.user-group-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  width: 100%;
  background: #f7f7f7;
  border: 1px solid #cccccc;
}
.user-group-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 100%;
  /* height: 80px; */
}
.user-group-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 33%;
  height: 80px;
}
.user-group-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: 24px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.03em;
  color: #000000;
}

.user-group-select-multi {
  position: relative;
  width: 100%;
}

.user-group-select {
  position: relative;
  width: 100%;
}

.user-group-select::after {
  content: url("/src/assets/communication/arrow_down.svg");
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 16px;
  color: #781d7e;
  padding: 11px 15px;
  pointer-events: none;
}
.user-group-dropdown-multi {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 8px;
  color: #000000;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  font-size: 16px;
}
.user-group-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 6px 12px 12px;
  gap: 12px;
  width: 100%;
  height: 48px;
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #cccccc;
  border-radius: 8px;
  color: #000000;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  font-size: 16px;
}
.search-users-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  gap: 8px;
  font-family: Poppins, sans-serif;
}
.search-users-txtbox {
  border-radius: 8px;
  border: 1px solid #cccccc;
  box-shadow: none;
}
.upload-excel-file {
  width: 100%;
  background-color: #ffffff;
}
.upload-excel-file .k-dropzone {
  background-color: #ffffff;
}
.upload-excel-file button {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
.recipients_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 1200px;
  height: 119px;
  border: 1px solid #e7ecef;
  border-radius: 8px;
}
.publish-rules-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
}
.publish-rules-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  width: 97%;
  background: #f7f7f7;
}
.publish-rules-table {
  width: 100%;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  font-size: 16px;
}
.publish-rules-table td {
  gap: 16px;
  padding-right: 16px;
}
.publish-rules-table tr:last-child td {
  padding-top: 5px;
}
.publish-rules-col {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 50%;
}
.publish-datetime {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 50%;
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 894px;
  position: absolute;
  width: 1190px;
  bottom: 10;
  /* top:99%; */
  background: #ffffff;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
}
.footer-left {
  width: 196px;
  height: 48px;
}
th.k-table-th.k-header {
  font-weight: 700;
}
.footer-save-btn {
  background-color: #eb6e1f;
  color: #ffffff;
  width: 89px;
  height: 48px;
  justify-content: center;
  padding: 0px 24px;
  gap: 8px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  text-align: center;
}
.footer-save-btn:hover,
.footer-next-btn:hover {
  background-color: #eb6e1f;
}
.footer-next-btn {
  background-color: #eb6e1f;
  width: 111px;
  height: 48px;
  color: #ffffff;
  justify-content: center;
  padding: 0px 24px;
  gap: 8px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  text-align: center;
}

.k-stepper .k-step-current .k-step-indicator {
  border: none;
}
.notification-steps .k-step-indicator {
  width: 20px;
  height: 20px;
}
.channel-list,
.info-category-section {
  list-style-type: none;
  overflow: hidden;
  width: 1200px;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.channel-list li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 150px;
  height: 24px;
}
.noti-form-title {
  padding-top: 20px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #000000;
}
.information-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.info-textbox {
  border: 1px solid #cccccc;
}
.info-editor {
  border: 1px solid #cccccc;
}
.k-button-solid-base.k-selected {
  border-color: #eb6e1f;
  background-color: #eb6e1f;
}
.k-button-group > .k-button {
  color: #781d7e;
}
.info-radio-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 20px;
  width: 420px;
  height: 24px;
}
.info-radio-btn {
  border: 1px solid #cccccc;
}
.info-category-section li {
  padding: 0px 12px 0px 0px;
}
.info-select {
  position: relative;
}
.info-select::after {
  content: url("/src/assets/communication/arrow_down.svg");
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 16px;
  color: #781d7e;
  padding: 11px 15px;
  pointer-events: none;
}
.info-category-dropdown {
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 5px 10px;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 8px;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
}
.info-category-infomsg {
  color: #666666;
  font-family: Poppins, sans-serif;
  line-height: "150%";
  letter-spacing: 0.01em;
  font-weight: 500;
}
.info-category-table td {
  padding: 0px 0px 15px 15px;
}
.pad-top-15 {
  padding-top: 15px !important;
}
.pad-top-6 {
  padding-top: 6px !important;
}
.info-datetime-picker {
  background-color: white;
}
.info-datetime-picker button {
  color: #781d7e;
  background-color: white;
  border: none;
}
.info-datetime-picker button:hover {
  background-color: white;
  border: none;
}
.k-input-solid.k-invalid,
.k-input-solid.ng-invalid.ng-touched,
.k-input-solid.ng-invalid.ng-dirty {
  border-color: #cccccc;
}
.gray-color-text {
  color: #666666;
}
.letter-spacing-1 {
  letter-spacing: 1px;
}
.font-family-poppins {
  font-family: Poppins, sans-serif;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.attach-item-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  width: 1200px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 8px;
  margin-top: 20px;
}
.attach-item-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  /* background-color: #781D7E; */
  width: 100%;
  min-height: 112px;
}
.item-image {
  width: 174px;
  height: auto;
}
.item-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 1000px;
  height: 21px;
}
.item-delete-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: #eb6e1f;
}
.attachment-add-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  gap: 8px;
  width: 134px;
  height: 31px;
  background: #ffffff;
  border: 1px solid #666666;
  border-radius: 8px;
  margin-top: 20px;
  color: #000000;
}
.attachment-replace-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  gap: 8px;
  width: 96px;
  height: 31px;
  background: #ffffff;
  border: 1px solid #eb6e1f;
  border-radius: 8px;
  color: #eb6e1f;
  font-style: normal;
}
.attachment-replace-btn:hover {
  background-color: transparent;
}

.no-pad-top {
  padding-top: 0px;
}
.attach-file-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  width: 1200px;
  height: 80px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 8px;
  margin-top: 20px;
}
.attach-file-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 100%;
  height: 48px;
}
.attach-file {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  padding: 8px;
  gap: 10px;
  /* width: 154px; */
  background: #e7ecef;
  border: 1px solid #cccccc;
  border-radius: 4px;
}
.attach-file-icon {
  width: 32px;
  height: 32px;
}
.attach-file-label {
  font-size: 16px;
  color: #781d7e;
}
.attach-file-space {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 990px;
  height: 21px;
}
.upload-attach-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  width: 1200px;
  height: 56px;
  background: #ffffff;
  border: 1px dashed #781d7e;
  border-radius: 8px;
  margin-top: 20px;
}
.upload-attach-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 100%;
  height: 24px;
}
.font-size-16 {
  font-size: 16px;
}
.footer-left-btn-groups {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 196px;
  height: 48px;
}

.footer-previous-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  gap: 8px;
  width: 118px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #eb6e1f;
  border-radius: 8px;
  color: #eb6e1f;
  font-weight: 600;
  font-size: 16px;
}
.no-pad-left {
  padding-left: 0px;
}
.review-form-table {
  border: none;
  border-collapse: collapse;
  width: 1200px;
  margin-bottom: 30px;
}
.review-form-table td {
  height: 32px;
  vertical-align: top;
}
.review-form-table td:first-child {
  width: 200px;
}
.review-form-table td:last-child {
  border-left: 1px solid #cccccc;
  padding: 6px 25px;
}
.message-body-div p {
  margin-top: 0 !important;
}
.attach-td {
  padding-top: 20px !important;
}
.attach-file-td {
  display: flex;
  flex-direction: row;
  height: 48px !important;
}
.review-file {
  margin-right: 10px;
}
.pad-top-20 {
  padding-top: 20px !important;
}
.bg-purple {
  background-color: #781d7e !important;
}
.display-none {
  display: none;
}
.recall-body {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 100%;
  min-height: 700px;
  /* height: 1791px; */
  left: 20px;
  /* margin-left: 20px; */
  top: 80px;
  /* background-color: #666666; */
}
.recall-body-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 0px;
  gap: 24px;
  width: 75%;
  min-height: 700px;
  background: #ffffff;
  min-width: 902px;
}
.recall-left-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  gap: 8px;
  width: 95%;
  height: 37px;
}
.recall-back-group {
  width: 86px;
  height: 28px;
}
.edit-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  width: 89px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #666666;
  border-radius: 8px;
}

.recall-back-group-div {
  position: absolute;
  width: 86px;
  height: 28px;
  left: 1px;
  top: 17px;
  cursor: grab;
  /* left: 24px;
    top: 4.5px; */
}
.recall-back-icon {
  position: absolute;
  width: 13px;
  height: 24px;
  top: 7px;
}
.recall-back-label {
  position: absolute;
  width: 54px;
  height: 28px;
  left: 26px;
  top: 4.5px;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  /* SBS Purple */

  color: #781d7e;
}
.recall-body-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  width: 25%;
  min-height: 900px;
  background: #f5f7fa;
}
.recall-status-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 17px;
}
.recall-status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 17px;

  width: 280px;
  height: 105px;

  background: #ffffff;
  border-radius: 8px;
}
.recall-status-label {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #333333;
}
.recall-status-value {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  gap: 8px;
  min-width: 87px;
  height: 36px;
  background: #36d49b;
  border-radius: 8px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.recall-action-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 17px;
}
.recall-action {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 17px;
  width: 280px;
  background: #ffffff;
  border-radius: 8px;
}
.duplicate-noti-btn {
  display: flex;
  flex-direction: column;
  width: 190px;
  height: 48px;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #666666;
  border-radius: 8px;
  color: #666666;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
}
.recall-audit-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 17px;
}
.recall-audit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 17px;
  width: 280px;
  background: #ffffff;
  border-radius: 8px;
}
.recall-audit-stepper {
  height: 188px;
}

.recall-tab-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  gap: 16px;
  width: 95%;
  height: 48px;
  /* background-color: #CCCCCC; */
}
.recall-tab-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 258px;
  height: 48px;
}
.recall-tab-group a {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #666666;
  padding-right: 20px;
  cursor: grab;
}
.recall-tab-group a:first-child {
  border-right: 1px solid #ccc;
}
.selected-tab {
  color: #eb6e1f !important;
}
.recall-form-table {
  border: none;
  border-collapse: collapse;
  /* width: 1200px; */
  margin-bottom: 30px;
}
.recall-info {
  width: 100%;
  /* padding: 0px 30px 0px 10px; */
}
.recall-form-table td:first-child {
  /* background-color: #36D49B; */
  width: 200px;
}
.recall-form-table td:last-child {
  border-left: 1px solid #cccccc;
  padding: 0px 20px;
}
.recall-form-table tr {
  vertical-align: top;
  height: 30px;
  color: #333333;
}
.recall-recipents-button-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  padding-left: 10px;
  padding-right: 30px;
  width: 870px;
  height: 153px;
}
.recall-recipients-table {
  margin-top: 20px;
  padding-left: 10px;
}
.recipients-recall-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  gap: 8px;
  width: 138.57px;
  height: 142px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  background-color: #ffffff;
  color: #333333;
}
.recall-selected-btn {
  background-color: #781d7e;
}
.recall-selected-btn:hover {
  background-color: #781d7e;
}
.recipients-number-label-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 60px;
  height: 36px;
}
.recipients-btn-label-number {
  width: 60px;
  height: 36px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 29px;
  line-height: 114%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #781d7e;
  justify-content: center;
  /* color: #FFFFFF; */
}
.recipients-btn-label {
  /* color: #FFFFFF; */
  font-weight: 600;
  color: #781d7e;
  /* width: 50px; */
}
.recall-selected-label {
  color: #ffffff;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.alert-form {
  font-family: Poppins, sans-serif;
}
.alert-form p {
  font-size: 16px;
  justify-content: center;
  text-align: center;
}
.alert-form li {
  padding: 5px 0px;
}
.error-message {
  font-family: Poppins, sans-serif;
  justify-content: center;
}
.error-message p {
  /* font-size: 16px; */
  font-weight: 500;
  color: red;
}
.notfication-info-dialog {
  font-family: Poppins, sans-serif;
}
.notfication-info-dialog p {
  font-size: 16;
  text-align: center;
  /* font-weight: 600; */
}
.text-align-center {
  text-align: center;
  line-height: 200%;
}
.inbox-custom-datepicker {
  width: 100%;
}
.publish-rules-table .react-datepicker-wrapper {
  width: 100% !important;
}

.notification-details__body {
  margin-top: 20px;
}

.notification-details-info {
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--antiflash-white);
}

.notification-details-info > .notification-details-info__item {
  width: 100%;
  display: flex;
  column-gap: 20px;
}

.notification-details-info
  > .notification-details-info__item
  > .notification-details-info__desc {
  width: 25%;
  padding: 10px 0px;
  font-weight: 500;
  border-right: 2px solid var(--antiflash-white);
}

.notification-details-info
  > .notification-details-info__item
  > .notification-details-info__value {
  width: 75%;
  padding: 10px 0px;
}
.information-details-alert-form {
  font-family: Poppins, sans-serif;
}
.information-details-alert-form .k-window.k-dialog {
  min-width: 400px;
}
.information-details-alert-form p {
  font-size: 16px;
  justify-content: center;
  text-align: center;
}
.information-details-alert-form li {
  padding: 5px 0px;
}

.keyboardInputDisable span.k-dateinput.k-input {
    pointer-events: none;
}

.Scroll .k-pager.k-pager-md.k-grid-pager {
    display: none;
}

input[type='checkbox']{
  border: 1px solid black !important;
  border-color: black !important;
}

.k-content p {
    font-family: inherit !important;
    color: black !important;
    font-size: 16px !important;
}

.k-editor .k-editor-content {
  font-family: cursive;
}