.info-datetime-picker{
    background-color: white;
    border-radius: 8px;
}
.info-datetime-picker button{
    color:#781D7E;
    background-color: white;
    border:none;
}
.info-datetime-picker button:hover{
    background-color: white;
    border: none;
}