div{
  font-family: Poppins,sans-serif;
}
.dr-infra-list-wrapper {
  width: calc(100vw - 240px);
}

.dr-infra-list-wrapper .module-page-title {
  padding-left: 26px;
  /* padding-bottom: 35px; */
}

.dr-infra-list-header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.dr-infra-list-header .module-page-title {
  display: flex;
  flex-grow: 1;
}

.dr-infra-list-header-right {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 8px;
  padding-right: 26px;
}

.dr-infra-list-header-action {
  width: 128px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #781d7e;
  border-radius: 8px;
  align-items: center;
  text-align: center;
  color: #781d7e;
}

.dr-infra-list-header-icon {
  width: 46px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #781d7e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dr-infra-list-column-action {
  width: 100px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #333333;
  border-radius: 8px;
  align-items: center;
  text-align: center;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
}

.dr-infra-list-wrapper .k-column-title {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #666666;
}
.dr-infra-list-wrapper .k-table-th{
  vertical-align: top !important;
}
.dr-infra-list-wrapper .k-table-td {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #333333;
}

.dr-infra-list-loader {
  display: flex;
  align-items: center;
  height: 50vh;
  justify-content: center;
}

.dr_infra_delete_popup_box{
  height: 180px;
    width: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: end;
    /* gap: 70px; */
    margin-bottom: 25px;
}

.dr_infra_delete_title{
  /* font-family: "Montserrat"; */
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 40px;
    margin-bottom: 15px;
    text-align: center;
}

.dr_infra_delete_footer_box{
  width: calc(100% - 60px);
    margin: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.dr_infra_delete_footer_cancel{
  color: #71277a;
    /* font-family: "Montserrat"; */
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    width: calc(50% - 10px);
    border: 1px solid #71277a;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dr_infra_delete_footer_delete{
  color: white;
    /* font-family: "Montserrat"; */
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    width: calc(50% - 10px);
    border: 1px solid #71277a;
    background: #71277a;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}