.button {
  cursor: pointer;
}

.button-primary {
  height: 32px;
  background-color: white;
  border: 1px solid #e74f0a;
  border-radius: 5px;
  color: #e74f0a;
}

.button-secondary {
  color: white;
  border: 1px solid #e74f0a;
  height: 32px;
  background-color: #e74f0a;
  border-radius: 5px;
}