.bus-checker-periods {
  height: 100%;
}

.bus-checker-periods > .container {
  margin: 24px;
}

.bus-checker-periods > .container > .header {
  width: 100%;
}

.bus-checker-periods > .container > .header > .button-back {
  display: flex;
  column-gap: 10px;
  font-size: 24px;
  align-items: center;
  color: #781d7e;
}

.bus-checker-periods > .container > .content-page {
  margin: 30px 0px;
}

.bus-checker-periods > .container > .footer-page {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.bus-checker-periods > .container > .footer-page > .wrapper {
  float: right;
  margin: 12px 24px;
  width: auto;
}

.form-date-select {
  display: grid;
  column-gap: 12px;
  grid-template-columns: 40% auto;
}

.form-group-datepicker {
  display: grid;
  column-gap: 12px;
  grid-template-columns: auto auto;
}

.input-group > .react-datepicker-wrapper {
  width: 100%;
}

.input-group > .input-date-label {
  margin-bottom: 10px;
}

.input-date-label {
  margin-bottom: 10px;
}

.form-uploader {
  margin-top: 30px;
}

.card-item {
  position: relative;
  margin: 10px 0px;
  padding: 16px;
  color: #73007D;
  border: 1px solid #E7ECEF;
  border-radius: 6px;
}

.card-item > .card-action {
  position: absolute;
  right: 30px;
  top: 0px;
  bottom: 0px;
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
}

.card-file-upload {
  padding: 8px;
  background-color: #E7ECEF;
  border-radius: 6px;
  width: fit-content;
}

.card-file-upload > span {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

/* Select Bus Checker Period */
.dropdown-select {
  z-index: 10;
  position: absolute;
  top: 65px;
  left: 0px;
  right: 0px;
  background-color: white;
  border: 1px solid rgb(231, 236, 239);
  border-radius: 10px;
  padding: 16px 0px;
}

.dropdown-select > .list-opt {
  overflow-y: scroll;
  max-height: 300px;
}


.dropdown-select > .list-opt > .select-item {
  margin: 10px 0px;
  padding: 16px;
}

.dropdown-select > .list-opt > .select-item:hover {
  background-color: rgb(246, 246, 246);
  cursor: pointer;
}

.dropdown-select > .list-opt > .select-label {
  margin: 20px 0px 10px;
  padding-left: 16px;
}

.dropdown-select > .search-options {
  margin: 10px 16px;
  padding: 8px 12px;
  color: #666666;
  border: 1px solid #E7ECEF;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  border-radius: 8px;
  min-width: 350px;
}

.card-item > .button-action-dropdown {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
}

.select-input-wrapper {
  display: grid;
  grid-template-columns: auto 80px;
}

.select-input-single-wrapper {
  display: grid;
  grid-template-columns: auto !important;
}

.select-input-wrapper > .wrapper-button {
  margin: 10px;
  width: 100%;
}

.select-input-wrapper > .wrapper-button > .button-add {
  width: 100% !important;
  height: 100% !important;
}

/*  */
.check-period-options > .select-input-wrapper {
  column-gap: 10px;
}

.no_margin > .select-input-single-wrapper > .card-item {
  margin: 0px !important;
  min-height: 30px;
}

.check-period-options > .select-input-wrapper > .card-item {
  margin: 0px;
}

.check-period-options > .select-input-wrapper > .wrapper-button {
  margin: 0px !important;
  width: 100% !important;
}

.check-period-options > .dropdown-select {
  padding: 8px 0px;
}

.check-period-options > .dropdown-select > .list-opt {
  overflow-y: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.check-period-options > .dropdown-select > .list-opt::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.check-period-options > .dropdown-select > .list-opt > .select-label {
  margin: 10px 0px;
}

.textfield {
  width: -webkit-fill-available;
  height: 44px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding-left: 10px;
}

.textfield:focus{
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}

.width-fit-content {
  width: fit-content;
}

.position-relative {
  position: relative;
}

.input-file-csv {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.download_template_link {
  text-decoration: none;
  color: black;
}