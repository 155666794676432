.dr-infra-list-wrapper {
    width: calc(100vw - 260px);
  }
.dr-infra-list-wrapper table{
  min-width: 1227px !important;
}
.dr-infra-list-wrapper .k-picker-solid:focus-within{
  background-color: #EB6E1F !important;
  color: #FFFFFF !important;
  border: #EB6E1F;
}