.notification_body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 0px;
  gap: 24px;
  width: 100%;
  background: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;

}
.notification_addheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  gap: 8px;
  width: calc(100vw - 300px);
  height: 48px;
  /* background-color: green; */
}
.back-title-group {
  cursor: pointer;
}
.back-title {
  position: absolute;
  width: 86px;
  height: 28px;
  left: 24px;
  top: 10px;
}
.back-text {
  width: 54px;
  height: 28px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #781d7e;
  padding: 0px 10px;
}
.back-icon {
  width: 16px;
  height: 16px;
}

.search-input {
  margin-top: 9px;
  height: 48px;
  width: 320px;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-shadow: none;
  background-color: white;
}
.search-input:focus {
  box-shadow: none;
  background-color: white;
}

.search-input:hover{
    background-color: white;
}

.Assignment_create_sub_box{
    display: flex;
    gap: 20px;
    margin: 10px 20px;
}

.Assignment_create_flex{
    flex: 1;
}

.Assignment_create_title{
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

.fullscreen-loader {
  position: absolute;
  background-color: #00000080;
  min-width: 100%;
  min-height: 92%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.Assignment_footer_button_box{
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% - 40px);
    align-items: center;
    justify-content: right;
    padding: 0px 20px;
    height: 80px;
    box-shadow: 0px -1px 0px 0px #00000040;
    background-color: white;
}

.Assignment_footer_button_cancel{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    height: 48px;
    border: 1px solid #F04A00;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F04A00;
    background: white;
    border-radius: 8px;
    cursor: pointer;
}

.Assignment_footer_button_send{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    height: 48px;
    border: 1px solid #F04A00;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: #F04A00;
    border-radius: 8px;
    cursor: pointer;
}

.Assignment_delete_dialog_title{
  font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 20px;
}

.Assignment_delete_dialog_text{
  font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.Assignment_delete_dialog_button_box{
  display: flex;
    gap: 20px;
    margin-top: 20px;
}

.Assignment_delete_dialog_button_cancel{
  height: 48px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    border: 1px solid #F04A00;
    background: white;
    color: #F04A00;
    border-radius: 8px;
    cursor: pointer;
}

.Assignment_delete_dialog_button_confirm{
  height: 48px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    border: 1px solid #F04A00;
    background: #F04A00;
    color: white;
    border-radius: 8px;
    cursor: pointer;
}